<script lang="ts">
  import { dev } from "$app/environment"

  import { Button, Modal } from "flowbite-svelte"

  let {
    open = $bindable(),
  }: {
    open: boolean
  } = $props()

  const app_install_url = dev
    ? "https://github.com/apps/centralci-for-localhost"
    : "https://github.com/apps/centralci"

  const app_install_text = dev
    ? "Install/Configure the CentralCI (for Localhost) Github app"
    : "Install/Configure the CentralCI Github app"
</script>

<Modal
  bind:open
  outsideclose
  autoclose={true}
  size="sm"
  placement="center"
  class="absolute drop-shadow-2xl border-2 border-white"
  title="Link an Organisation"
>
  <div class="flex flex-col gap-5">
    <p
      class="self-left text-base leading-relaxed text-gray-500 dark:text-gray-400"
    >
      Linking an organisation to Central CI happens automatically once our
      GitHub app is installed.
    </p>

    <Button class="self-end" href={app_install_url}>{app_install_text}</Button>
  </div>
</Modal>
