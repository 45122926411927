<script lang="ts">
  import { type RichUser } from "$lib/load_helpers"

  import {
    Avatar,
    Dropdown,
    DropdownItem,
    DropdownHeader,
    DropdownDivider,
    A,
  } from "flowbite-svelte"

  import { ProfileCardOutline, CashOutline } from "flowbite-svelte-icons"

  let { rich_user }: { rich_user: RichUser } = $props()
</script>

<Avatar size="sm" class="ml-3" id="avatar-menu" src={rich_user.avatar_url} />

<Dropdown
  placement="bottom-start"
  triggeredBy="#avatar-menu"
  class="overflow-y-auto py-1 drop-shadow-xl"
>
  <DropdownHeader>
    <span class="block font-bold">{rich_user.full_name}</span>
    <span class="block text-sm">{rich_user.email}</span>
  </DropdownHeader>
  <DropdownItem href="/settings/account" class="flex flex-row gap-2">
    <ProfileCardOutline />Account Settings
  </DropdownItem>
  <DropdownItem href="/settings/subscriptions" class="flex flex-row gap-2">
    <CashOutline />Manage Subscription
  </DropdownItem>
  <DropdownDivider />
  <DropdownItem href="/sign_out" class="flex flex-row gap-2">
    Logout
  </DropdownItem>
</Dropdown>
