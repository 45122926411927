<script lang="ts">
  import { type RichUser } from "$lib/load_helpers"

  import { page } from "$app/stores"
  import { Icons } from "$lib/components/centralci"
  import { ThemeSwitch } from "svelte-ux"

  import {
    Button,
    Navbar,
    NavBrand,
    NavLi,
    NavUl,
    NavHamburger,
    A,
  } from "flowbite-svelte"

  import ServiceStatusIndicator from "./service-status-indicator.svelte"
  import OrgSelection from "./org-selection.svelte"
  import UserAvatar from "./user-avatar.svelte"
  import SupportMenu from "./support-menu.svelte"

  let { rich_user }: { rich_user: RichUser | undefined | null } = $props()

  let activeUrl = $derived($page.url.pathname)
</script>

<header
  class="border-border/40 bg-background/95 supports-[backdrop-filter]:bg-background/60 sticky top-0 z-50 w-full border-b backdrop-blur xl:px-8"
>
  <Navbar color="none" fluid={true}>
    <NavBrand href="/">
      <div class="block xl:hidden">
        <Icons.CentralCi />
      </div>

      <div class="hidden xl:block">
        <div class="block dark:hidden">
          <Icons.LogoLight />
        </div>
        <div class="hidden dark:block">
          <Icons.LogoDark />
        </div>
      </div>
    </NavBrand>
    <!-- svelte-ignore a11y_no_static_element_interactions -->
    <div class="flex items-center sm:order-2">
      <NavHamburger />
    </div>

    <NavUl
      {activeUrl}
      ulClass="flex flex-col p-4 mt-4 sm:flex-row sm:space-x-8 rtl:space-x-reverse sm:mt-0 sm:text-sm sm:font-medium items-center gap-1"
      class="ml-2 flex-auto"
    >
      {#if rich_user}
        <NavLi activeClass="self-center">
          <OrgSelection user_orgs={rich_user.orgs}></OrgSelection>
        </NavLi>
      {/if}
      <NavLi activeClass="self-center" href="/blog">Blog</NavLi>
      <NavLi activeClass="self-center" href="/pricing">Pricing</NavLi>
      <NavLi activeClass="self-center" href="/settings/account">Settings</NavLi>
      <div class="grow"></div>
      <NavLi activeClass="self-center">
        <div class="flex flex-row items-center">
          <ServiceStatusIndicator as_badge={true} />
          <ThemeSwitch class="ml-2" />
          <SupportMenu {rich_user} />
          {#if rich_user}
            <UserAvatar {rich_user} />
            <!-- <Avatar size="sm" id="avatar-menu" src={rich_user.avatar_url} /> -->
          {:else}
            <A
              color="alternative"
              class="text-sm ml-2"
              href="/login/authenticate"
            >
              Log In
            </A>
            <Button size="sm" color="light" class="ml-2" href="/login/register">
              Register
            </Button>
          {/if}
        </div>
      </NavLi>
    </NavUl>
  </Navbar>
</header>
