<script lang="ts">
  import { XSolid, EnvelopeOutline } from "flowbite-svelte-icons"
  import { Icons } from "$lib/components/centralci"

  import Bluesky from "./icons/bluesky.svelte"
  import ServiceStatusIndicator from "./service-status-indicator.svelte"

  import { docs } from "$lib/dynamic/policydocdata"

  const companyDocs = docs.filter((d) => d.footer_region === "Company")
  const resourcesDocs = docs.filter((d) => d.footer_region === "Resources")
  const complianceDocs = docs.filter((d) => d.footer_region === "Compliance")
</script>

<footer
  class="py-6 md:px-8 md:py-0 mb-2 text-muted-foreground text-sm leading-loose"
>
  <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

  <div class="flex flex-row items-start gap-0 mb-3">
    <div class="flex flex-col gap-2 self-stretch">
      <div class="grow mb-4">
        <a href="/">
          <div class="block dark:hidden">
            <Icons.LogoLight />
          </div>
          <div class="hidden dark:block">
            <Icons.LogoDark />
          </div>
        </a>
      </div>

      <div class="grow"></div>

      <div class="ml-2">
        <ServiceStatusIndicator as_badge={false} />
      </div>

      <div class="ml-2">Made with love in Scotland and Canada</div>
      <div class="ml-2">
        Copyright Ⓒ {new Date().getFullYear()} CentralCI, Inc., a Delaware corporation
      </div>
    </div>

    <div class="grow"></div>

    <div class="flex flex-col gap-2 mr-10">
      <div class="font-bold small-caps">Company</div>
      <div>About</div>
      <div>Blog</div>
      <div>Contact Sales</div>
      <div>Security</div>
      <div>Legal</div>
      {#each companyDocs as doc}
        <a href={"/" + doc.slug} class="flex flex-row gap-2 items-center">
          <span>{doc.title}</span>
        </a>
      {/each}
    </div>

    <div class="flex flex-col gap-2 mr-10">
      <div class="font-bold small-caps">Resources</div>
      <div>Docs</div>
      <div>Changelog</div>
      <div>Support</div>
      <div><a href="https://centralcistatus.com">System Status</a></div>
      <div>Community Guides</div>
      <div>Concourse Tutorial</div>
      <div>Creators</div>
      {#each resourcesDocs as doc}
        <a href={"/" + doc.slug} class="flex flex-row gap-2 items-center">
          <span>{doc.title}</span>
        </a>
      {/each}
      <div><a href="humans.txt">humans.txt</a></div>
    </div>

    <div class="flex flex-col gap-2 mr-10">
      <div class="font-bold small-caps">Social</div>
      <a
        href="https://x.com/central_ci"
        class="flex flex-row gap-2 items-center"
      >
        <XSolid class="w-4 h-4" />
        <span>X / Twitter</span>
      </a>

      <a
        href="https://bsky.app/profile/centralci.com"
        class="flex flex-row gap-2 items-center"
      >
        <Bluesky class="w-4 h-4" />
        <span>Bluesky</span>
      </a>

      <a
        href="mailto:hello@centralci.com"
        class="flex flex-row gap-2 items-center"
      >
        <EnvelopeOutline class="w-4 h-4" />
        <span>Email</span>
      </a>
    </div>

    <div class="flex flex-col gap-2 mr-10">
      <div class="font-bold small-caps">Compliance</div>
      <div>
        Currently under review by <a
          href="https://safebase.io/products/trust-center"
        >
          Safebase
        </a>
      </div>
      {#each complianceDocs as doc}
        <a href={"/" + doc.slug} class="flex flex-row gap-2 items-center">
          <span>{doc.title}</span>
        </a>
      {/each}
    </div>
  </div>
</footer>
