<script lang="ts">
  // import type { SVGAttributes } from "svelte/elements";

  // export type $$Props = SVGAttributes<SVGElement>;
    const sizes = {
    xs: 'w-3 h-3',
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
    lg: 'w-6 h-6',
    xl: 'w-8 h-8'
  };

  let {
    ...restProps
  } = $props();
</script>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 36 35" {...restProps}>
  <g id="concourse.ci_start">
    <g id="Concourse_logo_white">
      <g id="concourse_logo_sm_b">
        <circle fill="currentColor" id="Oval" cx="20.7" cy="18" r="2.7"/>
        <path fill="currentColor" id="Shape" d="M23.5,20c-.5.6-1.2,1.1-1.9,1.3h0c.6,2.2,1.4,4.2,2.6,6.1-1,.5-2.1.9-3.2,1h-.2s-.2,0-.2,0h-.4c-.2,0-.5,0-.7,0-1,0-1.9-.2-2.8-.5-.4-.1-.9-.3-1.2-.5-.4-.2-.7-.4-1.1-.6-.3-.2-.6-.4-.8-.6-.2-.2-.4-.4-.6-.5-.3-.3-.5-.5-.5-.5,0,0,.1.2.4.6.1.2.3.4.5.6.2.3.5.5.8.7.3.3.7.5,1,.8.4.3.8.5,1.2.7.5.2.9.4,1.4.6.5.2,1,.3,1.6.4.3,0,.5,0,.8.1h.4s.2,0,.2,0h.2c.5,0,1.1,0,1.6-.1.9-.1,1.8-.4,2.6-.7.3.4.7.9,1,1.3,0,0,0,0,0,0l-.4.3h0s0,0,0,0c0,0,0,0,0,0h0s0,0-.1,0h-.3c0,.1-.1.2-.1.2h0s0,0,0,0h0s0,0,0,0h0c-.1,0-.2.1-.3.1s-.2,0-.3.1h0c0,0,0,0,0,0h0s0,0,0,0h-.2c-1.6.7-3.3,1-5,.9h-.2s-.2,0-.2,0h-.5c-.3,0-.6-.1-1-.2-1.3-.2-2.5-.7-3.7-1.3-.3-.1-.5-.3-.8-.4s-.5-.3-.7-.5c-.4-.3-.9-.7-1.3-1-.4-.3-.7-.6-1-1-.3-.3-.5-.6-.7-.8-.2-.2-.3-.4-.4-.6v-.2c-.1,0-.1,0,0,.2,0,.1.2.4.4.6.2.3.4.6.6.9.3.4.6.8.9,1.1.4.4.8.8,1.2,1.2.2.2.5.4.7.6.2.2.5.4.8.6.6.4,1.2.7,1.8,1,.6.3,1.3.5,2,.7.3.1.7.2,1.1.3h.5c0,0,.2.1.2.1h.3c.7.1,1.4.2,2.1.2,1.2,0,2.5-.2,3.7-.5h.2s0,0,0,0h.1c.1,0,.2-.1.3-.2h.3c0-.1.4-.2.4-.2.1,0,.2,0,.3-.1s0,0,.1,0h0s0,0,0,0h0s0,0,0,0h0s.4-.3.4-.3c.2-.1.4-.2.6-.3.4.4.8.7,1.2,1.1,0,0,1.9,2,3,1.2,1-.8-.1-3.2-.1-3.2-1.5-4.4-4.4-8.2-8.2-10.9Z"/>
        <path fill="currentColor" id="Shape1" data-name="Shape" d="M17.1,17.2c-2.1-.6-4.3-.9-6.4-.9,0-1.2.4-2.3.9-3.3h0c0-.1,0-.3,0-.3l.2-.3c.1-.2.2-.4.4-.6.5-.8,1.2-1.5,1.9-2.1.3-.3.7-.5,1.1-.8.3-.2.7-.4,1.1-.6.3-.1.6-.3,1-.4.3-.1.6-.2.8-.2.4-.1.7-.2.7-.2,0,0-.2,0-.7,0-.2,0-.5,0-.8.1-.4,0-.7.2-1,.3-.4.1-.8.3-1.2.4-.4.2-.9.4-1.2.7-.4.3-.9.6-1.2.9-.4.3-.8.7-1.1,1.1-.2.2-.4.4-.5.6l-.2.3v.2c-.1,0-.2.2-.2.2-.3.4-.5.9-.7,1.4-.4.8-.7,1.8-.8,2.7h0c-.5,0-1.1.2-1.6.2v-.8h0c0-.2,0,0,0,0h0c0,0,0-.1,0-.2,0-.1,0-.2,0-.3,0,0,0-.1,0-.2h0c0-.1,0,0,0,0h0c0-.1,0-.2,0-.3,0-.1,0-.2,0-.3h0s0,0,0,0h0s0-.1,0-.1v-.2c.4-1.7,1.1-3.2,2-4.6v-.2c.1,0,.3-.2.3-.2l.3-.4c.2-.2.4-.5.6-.7.9-.9,1.9-1.8,3-2.4.3-.2.5-.3.8-.4.3-.1.5-.3.8-.4.5-.2,1-.4,1.5-.5.4-.1.9-.2,1.3-.3.4,0,.8-.1,1.1-.2.3,0,.5,0,.7,0h.2-.2c-.2,0-.4,0-.7,0-.3,0-.7,0-1.1,0-.5,0-.9.1-1.4.2-.6.1-1.1.2-1.6.4-.3,0-.6.2-.9.3-.3.1-.6.2-.9.4-.6.3-1.2.6-1.8,1-.6.4-1.1.8-1.7,1.3-.3.2-.5.5-.8.7l-.3.4-.2.2-.2.2c-.4.5-.9,1.1-1.2,1.7-.6,1-1.2,2.2-1.5,3.3v.2c0,0,0,0,0,0h0c0,.2,0,.4,0,.5,0,.1,0,.2,0,.3,0,.1,0,.2,0,.4,0,.1,0,.2,0,.3,0,0,0,0,0,.1h0s0,.1,0,.1h0c0,.3,0,.4,0,.6,0,.4,0,.7,0,.9-.5.1-1,.3-1.5.5,0,0-2.7.6-2.5,1.9.1,1.2,2.8,1.5,2.8,1.5,4.6.9,9.3.4,13.5-1.4-.2-.7-.3-1.5-.1-2.3Z"/>
        <path fill="currentColor" id="Shape2" data-name="Shape" d="M35.6,16.2c0-.7-.2-1.4-.3-2.1,0-.4-.2-.7-.3-1l-.2-.5v-.2c0,0-.2-.3-.2-.3-.3-.6-.6-1.3-.9-1.9-.6-1.1-1.4-2.1-2.3-2.9h-.1c0-.1,0-.2,0-.2h-.1c0-.2-.2-.2-.3-.3,0,0-.2-.2-.3-.2-.1,0-.2-.2-.3-.2l-.2-.2h-.1c0,0,0-.1,0-.1h0c0,0,0,0,0,0h0c-.1-.1-.3-.2-.4-.3-.2-.2-.4-.3-.5-.4.1-.6.3-1.1.4-1.7.1-.9.8-2.7-.5-3.2-1.2-.5-2.7,1.7-2.7,1.7-3.1,3.5-4.9,7.9-5.3,12.6.8.2,1.5.5,2.1,1h0c1.5-1.5,2.8-3.2,3.9-5,1,.6,1.9,1.4,2.6,2.3h.1c0,.1.1.3.1.3l.2.3c.1.2.3.4.4.6.5.9.8,1.8,1,2.7,0,.4.1.9.2,1.3,0,.4,0,.8,0,1.2,0,.3,0,.7-.1,1,0,.3-.1.6-.2.8-.1.4-.2.7-.2.7,0,0,.1-.2.3-.6,0-.2.2-.5.3-.8.1-.3.2-.7.3-1,0-.4.1-.8.2-1.3,0-.5,0-1,0-1.4,0-.5-.1-1-.2-1.5-.1-.5-.3-1-.4-1.5,0-.2-.2-.5-.3-.8l-.2-.3v-.2c0,0-.1-.2-.1-.2-.3-.5-.5-.9-.9-1.3-.6-.7-1.2-1.4-2-2,.2-.5.4-1,.6-1.5h0s.4.3.4.3h0s0,0,0,0c0,0,0,0,0,0h0s.1,0,.1,0l.3.2h.1c0,0,0,.1,0,.1h0s0,0,0,0c0,0,0,0,0,0h0c0,0,.2.1.3.2l.3.2h0c0,0,0,0,0,0h0s0,0,0,0h.1c1.3,1.2,2.4,2.6,3.2,4.1v.2c.1,0,.2.2.2.2l.2.5c.1.3.3.6.3.9.4,1.2.7,2.5.7,3.8,0,.3,0,.6,0,.9s0,.6,0,.9c0,.5-.1,1.1-.3,1.6-.1.5-.2.9-.4,1.3-.1.4-.3.7-.4,1-.1.3-.2.5-.3.6,0,.1-.1.2-.1.2,0,0,0,0,.1-.2,0-.1.2-.3.4-.6.1-.3.3-.6.5-1,.2-.4.4-.9.5-1.3.2-.5.3-1.1.4-1.6,0-.3.1-.6.1-.9,0-.3,0-.6,0-1,0-.7,0-1.3,0-2Z"/>
      </g>
    </g>
  </g>
</svg>
