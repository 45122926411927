<script lang="ts">
  import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownDivider,
  } from "flowbite-svelte"

  import { ChevronDownOutline } from "flowbite-svelte-icons"
  import { type Org, selected_org } from "$lib/stores/selected_org"
  import InstallGithubAppModal from "./install-github-app-modal.svelte"

  let { user_orgs }: { user_orgs: Org[] } = $props()

  if (
    user_orgs.length > 0 &&
    user_orgs.some((x) => x.uuid === $selected_org.uuid)
  ) {
    $selected_org = user_orgs[0]
  }

  let org_dropdown_open = $state(false)
  let new_org_modal_open = $state(false)

  function onOrgSelection(org: Org) {
    return (evt: MouseEvent) => {
      evt.preventDefault()
      $selected_org = org
      org_dropdown_open = false
    }
  }

  function onNewOrgRequested(evt: MouseEvent) {
    evt.preventDefault()
    org_dropdown_open = false
    new_org_modal_open = true
  }
</script>

{#if user_orgs.length > 0}
  <Button size="sm" color="alternative" class="py-1 min-w-32">
    {$selected_org.name}<ChevronDownOutline
      class="w-fit h-6 ms-2 text-white dark:text-white"
    />
  </Button>
  <Dropdown class="w-fit" bind:open={org_dropdown_open}>
    {#each user_orgs as org}
      <DropdownItem on:click={onOrgSelection(org)}>
        {org.name}
      </DropdownItem>
    {/each}
    <DropdownDivider />
    <DropdownItem on:click={onNewOrgRequested}>Add New...</DropdownItem>
  </Dropdown>
{:else}
  <Button size="sm" on:click={onNewOrgRequested} class="py-1 min-w-32">
    Add Organization
  </Button>
{/if}

<InstallGithubAppModal bind:open={new_org_modal_open} />
